<template>
  <div class="w-full flex flex-col">
    <!-- Header -->
    <div class="flex gap-2 px-4 py-2 md:p-4 items-center">
      <div class="w-full flex gap-2 lg:gap-3 items-center">
        <div class="flex-1">
          <fw-heading>{{ $t('notifications') }}</fw-heading>
        </div>
        <!-- Main navigation -->
        <div class="flex gap-2 items-center">
          <fw-button
            :aria-label="$t('inbox')"
            type="basic"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            :class="{
              'bg-white bg-opacity-70 text-primary': filterType == 'inbox',
            }"
            @click.native="changeFilter('inbox')"
          >
            <fw-icon-inbox class="w-5 h-5 md:w-6 md:h-6" />
          </fw-button>
          <fw-button
            :aria-label="$t('archive')"
            type="basic"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            :class="{
              'bg-white bg-opacity-70 text-primary': filterType == 'archived',
            }"
            @click.native="changeFilter('archived')"
          >
            <fw-icon-archive class="w-5 h-5 md:w-6 md:h-6" />
          </fw-button>
          <fw-button
            v-if="false"
            :aria-label="$t('settings')"
            type="basic"
            class="rounded-full w-8 h-8 md:w-12 md:h-12 items-center flex justify-center"
            :class="{
              'bg-white bg-opacity-70 text-primary': filterType == 'configuration',
            }"
            @click.native="changeFilter('configuration')"
          >
            <fw-icon-list-settings class="w-5 h-5 md:w-6 md:h-6" />
          </fw-button>
        </div>
      </div>
    </div>

    <!-- Main -->
    <div class="flex-1 flex flex-col p-5">
      <fw-panel v-if="filterType == 'configuration'" :title="$t('settings')">
        <div v-for="(configuration, index) in configurations" :key="configuration.key || configuration.title">
          <b>{{ configuration.title }}</b>
          <div v-if="configuration.key">
            <span v-if="configuration.active" @click="updateConfiguration(configuration.key, false)">{{
              $t('active')
            }}</span>
            <span v-else @click="updateConfiguration(configuration.key, true)">{{ $t('inactive') }}</span>
          </div>
          <div v-else>
            <span v-if="configuration.active == null" @click="updateConfigurationGroup(index, false)">{{
              $t('partial')
            }}</span>
            <span v-else-if="configuration.active" @click="updateConfigurationGroup(index, false)">{{
              $t('active')
            }}</span>
            <span v-else @click="updateConfigurationGroup(index, true)">{{ $t('inactive') }}</span>
          </div>

          <div v-if="configuration.children">
            <div v-for="child in configuration.children" :key="child.key" style="padding-left: 25px">
              <b>{{ child.title }}</b>
              <span v-if="child.active" @click="updateConfiguration(child.key, false)">{{ $t('active') }}</span>
              <span v-else @click="updateConfiguration(child.key, true)">{{ $t('inactive') }}</span>
              <br />
            </div>
          </div>
          <br />
        </div>
      </fw-panel>
      <fw-panel v-else :title="filterType == 'inbox' ? $t('inbox') : $t('archive')">
        <BlockListNotifications
          :key="filterType"
          type="full"
          :notifications="notifications"
          :view="filterType"
          @remove-from-list="removeNotificationFromList"
        />
      </fw-panel>
    </div>
  </div>
</template>

<script>
import BlockListNotifications from '@/fw-modules/fw-core-vue/notifications/components/blocks/BlockListNotifications'
import ServiceNotifications from '@/fw-modules/fw-core-vue/notifications/services/ServiceNotifications'

export default {
  components: {
    BlockListNotifications,
  },

  data() {
    return {
      loading: false,
      filterType: 'inbox',
      notifications: [],
      subscription: null,
      configurations: [],
    }
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    },
  },

  created() {
    this.loadNotifications()
  },

  beforeDestroy() {
    if (this.subscription) {
      this.subscription.destroy()
    }
  },

  methods: {
    async changeFilter(type) {
      if (!this.loading && type != this.filterType) {
        this.filterType = type

        if (this.filterType === 'configuration') {
          await this.loadConfigurations()
        } else {
          await this.loadNotifications()
        }
      }
    },

    removeNotificationFromList(key) {
      for (let idx in this.notifications) {
        if (this.notifications[idx].key == key) {
          this.notifications.splice(idx, 1)
          break
        }
      }
    },

    unload() {
      if (this.subscription && this.filterType != 'inbox') {
        this.subscription.destroy()
      }
    },
    async loadNotifications() {
      if (this.loading) return
      this.loading = true

      this.unload()

      try {
        const response = await ServiceNotifications.all(this.filterType)
        this.notifications = response.notifications
        if (this.filterType == 'inbox') {
          this.subscription = ServiceNotifications.subscriptionInboxRefresh(this.notifications)
        }
      } finally {
        this.loading = false
      }
    },
    async loadConfigurations() {
      if (this.loading) return
      this.loading = true

      this.unload()

      try {
        this.configurations = await ServiceNotifications.configurations()
      } finally {
        this.loading = false
      }
    },

    getActiveConfigurations() {
      const keys = new Set()
      for (let configuration of this.configurations) {
        if (configuration.key) {
          if (configuration.active) {
            keys.add(configuration.key)
          }
        } else {
          for (let child of configuration.children) {
            if (child.active) {
              keys.add(child.key)
            }
          }
        }
      }
      return keys
    },
    async updateConfiguration(key, active) {
      const keys = this.getActiveConfigurations()
      if (active) keys.add(key)
      else keys.delete(key)

      this.configurations = await ServiceNotifications.updateConfigurations([...keys])
    },
    async updateConfigurationGroup(idx, active) {
      const keys = this.getActiveConfigurations()
      for (let child of this.configurations[idx].children) {
        if (active) keys.add(child.key)
        else keys.delete(child.key)
      }

      this.configurations = await ServiceNotifications.updateConfigurations([...keys])
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "notifications": "Notificações",
    "inbox": "Caixa de entrada",
    "archive":"Arquivo",
    "settings":"Configurações",
    "active": "Activo",
    "inactive": "Inactivo",
    "partial": "Parcial"
  },
  "en": {
    "notifications": "Notifications",
    "inbox": "Inbox",
    "archive":"Archive",
    "settings":"Settings",
    "active": "Active",
    "inactive": "Inactive",
    "partial": "Partial"
  }
}
</i18n>
